import { useForm } from 'react-hook-form';
import { useEquipmentStore } from '../../store/equipmentStore';
import { Equipment } from '../../types/equipment';
import { LOCATIONS, SERVICE_TYPES } from '../../utils/constants';
import { calculateNextMaintenanceDate } from '../../utils/dateUtils';

export function AddEquipmentForm() {
  const { register, handleSubmit, reset, watch } = useForm<Omit<Equipment, 'id' | 'status'>>();
  const addEquipment = useEquipmentStore((state) => state.addEquipment);
  const notes = watch('notes', '');
  const serviceType = watch('serviceType');
  const lastMaintenance = watch('lastMaintenance');

  const onSubmit = (data: Omit<Equipment, 'id' | 'status'>) => {
    const nextMaintenance = calculateNextMaintenanceDate(data.lastMaintenance, data.serviceType);
    
    const equipment = {
      ...data,
      id: crypto.randomUUID(),
      status: 'operational',
      nextMaintenance
    };

    addEquipment(equipment);
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Equipment/Task Name
        </label>
        <input
          {...register('name', { required: true })}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Location
        </label>
        <select
          {...register('location', { required: true })}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          required
        >
          <option value="">Select Location</option>
          {LOCATIONS.map((loc) => (
            <option key={loc} value={loc}>{loc}</option>
          ))}
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Model
        </label>
        <input
          {...register('model', { required: true })}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Serial Number
        </label>
        <input
          {...register('serialNumber', { required: true })}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Last Maintenance Date
        </label>
        <input
          type="date"
          {...register('lastMaintenance', { required: true })}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Type of Service
        </label>
        <select
          {...register('serviceType', { required: true })}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          required
        >
          <option value="">Select Service Type</option>
          {Object.entries(SERVICE_TYPES).map(([key, description]) => (
            <option key={key} value={key}>{description}</option>
          ))}
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Service Description
        </label>
        <textarea
          {...register('serviceDescription')}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          rows={3}
          placeholder="Describe the service performed..."
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Notes
        </label>
        <textarea
          {...register('notes')}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          rows={3}
          placeholder="Add any additional notes..."
        />
      </div>

      <button
        type="submit"
        className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition-colors"
      >
        Add Equipment/Task
      </button>
    </form>
  );
}