import { Wrench } from 'lucide-react';

export function Header() {
  return (
    <div className="text-center py-8 bg-white/80 backdrop-blur-sm">
      <div className="flex justify-center mb-2">
        <Wrench className="w-8 h-8 text-red-600 -rotate-45" />
        <Wrench className="w-8 h-8 text-blue-600 rotate-45 -ml-4" />
      </div>
      <h1 className="text-3xl font-bold text-gray-800">Mac's Facilities Team</h1>
      <p className="text-gray-600 mt-2">For internal use only.</p>
    </div>
  );
}