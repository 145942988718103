import { useForm } from 'react-hook-form';
import { useTechnicianStore } from '../../store/technicianStore';
import { Technician } from '../../types/technician';

export function AddTechnician() {
  const { register, handleSubmit, reset } = useForm<Omit<Technician, 'id' | 'isAvailable'>>();
  const addTechnician = useTechnicianStore((state) => state.addTechnician);

  const onSubmit = (data: Omit<Technician, 'id' | 'isAvailable'>) => {
    const technician: Technician = {
      ...data,
      id: crypto.randomUUID(),
      isAvailable: true,
      specialization: data.specialization.filter(Boolean)
    };
    addTechnician(technician);
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Name
        </label>
        <input
          {...register('name', { required: true })}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          placeholder="Enter technician name"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Email
        </label>
        <input
          type="email"
          {...register('email', { required: true })}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          placeholder="Enter email address"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Phone
        </label>
        <input
          type="tel"
          {...register('phone', { required: true })}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          placeholder="Enter phone number"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Specializations (comma-separated)
        </label>
        <input
          {...register('specialization', { 
            setValueAs: (v: string) => v.split(',').map(s => s.trim())
          })}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          placeholder="HVAC, Electrical, Plumbing, etc."
        />
      </div>

      <button
        type="submit"
        className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition-colors"
      >
        Add Technician
      </button>
    </form>
  );
}