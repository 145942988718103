interface ActionButtonProps {
  variant: 'primary' | 'secondary' | 'danger';
  children: React.ReactNode;
  onClick?: () => void;
}

export function ActionButton({ variant, children, onClick }: ActionButtonProps) {
  const baseClasses = "px-6 py-3 rounded-md font-medium transition-colors w-full text-center";
  const variantClasses = {
    primary: "bg-blue-200 text-blue-800 hover:bg-blue-300",
    secondary: "bg-gray-200 text-gray-800 hover:bg-gray-300",
    danger: "bg-red-600 text-white hover:bg-red-700"
  };

  return (
    <button 
      className={`${baseClasses} ${variantClasses[variant]}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
}