import { useProjectStore } from '../../store/projectStore';
import { Project } from '../../types/project';
import { ProjectForm } from './ProjectForm';

export function AddProject() {
  const addProject = useProjectStore((state) => state.addProject);

  const handleSubmit = (data: Omit<Project, 'id' | 'workOrders'>) => {
    const project: Project = {
      ...data,
      id: crypto.randomUUID(),
      workOrders: [],
    };
    addProject(project);
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <h2 className="text-2xl font-bold mb-4">Add New Project</h2>
      <ProjectForm onSubmit={handleSubmit} />
    </div>
  );
}