import { useState } from 'react';
import { useTechnicianStore } from '../../store/technicianStore';
import { useWorkOrderStore } from '../../store/workOrderStore';
import { Badge, Trash2, Edit2, X, Check } from 'lucide-react';
import { Technician } from '../../types/technician';

export function TechnicianList() {
  const { technicians, deleteTechnician, updateTechnician } = useTechnicianStore();
  const getWorkOrdersByTechnician = useWorkOrderStore((state) => state.getWorkOrdersByTechnician);
  const [editingId, setEditingId] = useState<string | null>(null);
  const [editForm, setEditForm] = useState<Technician | null>(null);

  const handleDelete = (techId: string) => {
    const workOrders = getWorkOrdersByTechnician(techId);
    if (workOrders.length > 0) {
      if (!window.confirm(`This technician has ${workOrders.length} active work orders. Are you sure you want to delete them?`)) {
        return;
      }
    }
    deleteTechnician(techId);
  };

  const handleEdit = (tech: Technician) => {
    setEditingId(tech.id);
    setEditForm(tech);
  };

  const handleSave = () => {
    if (editForm && editingId) {
      updateTechnician(editingId, editForm);
      setEditingId(null);
      setEditForm(null);
    }
  };

  const handleCancel = () => {
    setEditingId(null);
    setEditForm(null);
  };

  return (
    <div className="space-y-4">
      {technicians.map((tech) => {
        const workOrders = getWorkOrdersByTechnician(tech.id);
        const activeWorkOrders = workOrders.filter(
          (order) => order.status !== 'completed'
        );

        return (
          <div
            key={tech.id}
            className="border rounded-lg p-4 hover:bg-gray-50 transition-colors"
          >
            {editingId === tech.id ? (
              <div className="space-y-4">
                <div className="flex justify-between items-center">
                  <input
                    className="border rounded px-2 py-1 w-full mr-4"
                    value={editForm?.name || ''}
                    onChange={(e) => setEditForm(prev => prev ? {...prev, name: e.target.value} : null)}
                    placeholder="Technician Name"
                  />
                  <div className="flex gap-2">
                    <button
                      onClick={handleSave}
                      className="text-green-500 hover:text-green-700"
                      title="Save changes"
                    >
                      <Check className="w-5 h-5" />
                    </button>
                    <button
                      onClick={handleCancel}
                      className="text-red-500 hover:text-red-700"
                      title="Cancel editing"
                    >
                      <X className="w-5 h-5" />
                    </button>
                  </div>
                </div>
                <div className="space-y-2">
                  <input
                    type="email"
                    className="border rounded px-2 py-1 w-full"
                    value={editForm?.email || ''}
                    onChange={(e) => setEditForm(prev => prev ? {...prev, email: e.target.value} : null)}
                    placeholder="Email"
                  />
                  <input
                    type="tel"
                    className="border rounded px-2 py-1 w-full"
                    value={editForm?.phone || ''}
                    onChange={(e) => setEditForm(prev => prev ? {...prev, phone: e.target.value} : null)}
                    placeholder="Phone"
                  />
                  <input
                    className="border rounded px-2 py-1 w-full"
                    value={editForm?.specialization.join(', ') || ''}
                    onChange={(e) => setEditForm(prev => prev ? {
                      ...prev,
                      specialization: e.target.value.split(',').map(s => s.trim()).filter(Boolean)
                    } : null)}
                    placeholder="Specializations (comma-separated)"
                  />
                </div>
              </div>
            ) : (
              <>
                <div className="flex items-center justify-between mb-2">
                  <h3 className="font-semibold text-lg">{tech.name}</h3>
                  <div className="flex items-center gap-4">
                    <span className="flex items-center gap-2">
                      <Badge className="w-4 h-4" />
                      {activeWorkOrders.length} Active Orders
                    </span>
                    <button
                      onClick={() => handleEdit(tech)}
                      className="text-blue-500 hover:text-blue-700 transition-colors"
                      title="Edit technician"
                    >
                      <Edit2 className="w-4 h-4" />
                    </button>
                    <button
                      onClick={() => handleDelete(tech.id)}
                      className="text-red-500 hover:text-red-700 transition-colors"
                      title="Delete technician"
                    >
                      <Trash2 className="w-4 h-4" />
                    </button>
                  </div>
                </div>
                <div className="text-sm text-gray-600 space-y-1">
                  <p>Email: {tech.email}</p>
                  <p>Phone: {tech.phone}</p>
                  <p>Specializations: {tech.specialization.join(', ')}</p>
                  <p>Status: {tech.isAvailable ? 'Available' : 'Busy'}</p>
                </div>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
}