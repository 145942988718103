import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Project } from '../types/project';

interface ProjectStore {
  projects: Project[];
  addProject: (project: Project) => void;
  updateProject: (id: string, updates: Partial<Project>) => void;
  deleteProject: (id: string) => void;
  getProjectsByLocation: (location: string) => Project[];
  addWorkOrderToProject: (projectId: string, workOrderId: string) => void;
}

export const useProjectStore = create<ProjectStore>()(
  persist(
    (set, get) => ({
      projects: [],
      addProject: (project) =>
        set((state) => ({ projects: [...state.projects, project] })),
      updateProject: (id, updates) =>
        set((state) => ({
          projects: state.projects.map((project) =>
            project.id === id ? { ...project, ...updates } : project
          ),
        })),
      deleteProject: (id) =>
        set((state) => ({
          projects: state.projects.filter((project) => project.id !== id),
        })),
      getProjectsByLocation: (location) =>
        get().projects.filter((project) => project.location === location),
      addWorkOrderToProject: (projectId, workOrderId) =>
        set((state) => ({
          projects: state.projects.map((project) =>
            project.id === projectId
              ? { ...project, workOrders: [...project.workOrders, workOrderId] }
              : project
          ),
        })),
    }),
    {
      name: 'project-storage',
    }
  )
);