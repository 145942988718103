import { useState } from 'react';
import { useEquipmentStore } from '../../store/equipmentStore';
import { Settings, AlertTriangle, CheckCircle, Trash2, Edit2, X, Check } from 'lucide-react';
import { Equipment } from '../../types/equipment';
import { LOCATIONS } from '../../utils/constants';
import { formatDate } from '../../utils/dateUtils';

export function EquipmentList() {
  const { equipment, deleteEquipment, editEquipment } = useEquipmentStore();
  const [editingId, setEditingId] = useState<string | null>(null);
  const [editForm, setEditForm] = useState<Equipment | null>(null);

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'operational':
        return <CheckCircle className="text-green-500" />;
      case 'maintenance-needed':
        return <AlertTriangle className="text-yellow-500" />;
      case 'under-maintenance':
        return <Settings className="text-blue-500 animate-spin" />;
      default:
        return null;
    }
  };

  const handleEdit = (item: Equipment) => {
    setEditingId(item.id);
    setEditForm(item);
  };

  const handleSave = () => {
    if (editForm && editingId) {
      editEquipment(editingId, editForm);
      setEditingId(null);
      setEditForm(null);
    }
  };

  const handleCancel = () => {
    setEditingId(null);
    setEditForm(null);
  };

  if (equipment.length === 0) {
    return (
      <div className="bg-white rounded-lg shadow-md p-6 text-center">
        <h2 className="text-2xl font-bold mb-4">Equipment/Task Status</h2>
        <p className="text-gray-600">No equipment or tasks added yet. Add some to get started!</p>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <h2 className="text-2xl font-bold mb-4">Equipment/Task Status</h2>
      <div className="space-y-4">
        {equipment.map((item) => (
          <div
            key={item.id}
            className="border rounded-lg p-4 hover:bg-gray-50 transition-colors"
          >
            {editingId === item.id ? (
              <div className="space-y-4">
                <div className="flex justify-between">
                  <input
                    className="border rounded px-2 py-1 w-full"
                    value={editForm?.name || ''}
                    onChange={(e) => setEditForm(prev => prev ? {...prev, name: e.target.value} : null)}
                    placeholder="Equipment/Task Name"
                  />
                  <div className="flex gap-2 ml-2">
                    <button
                      onClick={handleSave}
                      className="text-green-500 hover:text-green-700"
                      title="Save changes"
                    >
                      <Check className="w-5 h-5" />
                    </button>
                    <button
                      onClick={handleCancel}
                      className="text-red-500 hover:text-red-700"
                      title="Cancel editing"
                    >
                      <X className="w-5 h-5" />
                    </button>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Model</label>
                    <input
                      className="border rounded px-2 py-1 w-full"
                      value={editForm?.model || ''}
                      onChange={(e) => setEditForm(prev => prev ? {...prev, model: e.target.value} : null)}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Location</label>
                    <select
                      className="border rounded px-2 py-1 w-full"
                      value={editForm?.location || ''}
                      onChange={(e) => setEditForm(prev => prev ? {...prev, location: e.target.value} : null)}
                    >
                      {LOCATIONS.map((loc) => (
                        <option key={loc} value={loc}>{loc}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Last Maintenance</label>
                    <input
                      type="date"
                      className="border rounded px-2 py-1 w-full"
                      value={editForm?.lastMaintenance || ''}
                      onChange={(e) => setEditForm(prev => prev ? {...prev, lastMaintenance: e.target.value} : null)}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Next Maintenance</label>
                    <input
                      type="date"
                      className="border rounded px-2 py-1 w-full"
                      value={editForm?.nextMaintenance || ''}
                      onChange={(e) => setEditForm(prev => prev ? {...prev, nextMaintenance: e.target.value} : null)}
                    />
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Notes</label>
                  <textarea
                    className="border rounded px-2 py-1 w-full"
                    value={editForm?.notes || ''}
                    onChange={(e) => setEditForm(prev => prev ? {...prev, notes: e.target.value} : null)}
                    rows={2}
                  />
                </div>
              </div>
            ) : (
              <>
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <h3 className="font-semibold">{item.name}</h3>
                    {getStatusIcon(item.status)}
                  </div>
                  <div className="flex gap-2">
                    <button
                      onClick={() => handleEdit(item)}
                      className="text-blue-500 hover:text-blue-700"
                      title="Edit equipment/task"
                    >
                      <Edit2 className="w-4 h-4" />
                    </button>
                    <button
                      onClick={() => {
                        if (window.confirm('Are you sure you want to delete this equipment/task?')) {
                          deleteEquipment(item.id);
                        }
                      }}
                      className="text-red-500 hover:text-red-700"
                      title="Delete equipment/task"
                    >
                      <Trash2 className="w-4 h-4" />
                    </button>
                  </div>
                </div>
                <p className="text-sm text-gray-600">Model: {item.model}</p>
                <p className="text-sm text-gray-600">Location: {item.location}</p>
                <p className="text-sm text-gray-600">Serial: {item.serialNumber}</p>
                <p className="text-sm text-gray-600">
                  Last Maintenance: {formatDate(item.lastMaintenance)}
                </p>
                <p className="text-sm text-gray-600">
                  Next Maintenance: {formatDate(item.nextMaintenance)}
                </p>
                <p className="text-sm text-gray-600">Service Type: {item.serviceType}</p>
                {item.notes && (
                  <p className="text-sm text-gray-600 mt-2">Notes: {item.notes}</p>
                )}
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}