import { useEffect } from 'react';
import { useEquipmentStore } from '../../store/equipmentStore';
import { useTechnicianStore } from '../../store/technicianStore';
import { checkMaintenanceStatus } from '../../utils/maintenanceChecker';

export function MaintenanceScheduler() {
  const equipment = useEquipmentStore((state) => state.equipment);
  const technicians = useTechnicianStore((state) => state.technicians);
  const updateEquipment = useEquipmentStore((state) => state.updateEquipment);

  useEffect(() => {
    const checkEquipment = async () => {
      for (const item of equipment) {
        const status = await checkMaintenanceStatus(item, technicians);
        
        if (status.requiresMaintenance && status.assignedTech) {
          updateEquipment(item.id, {
            status: 'maintenance-needed',
            assignedTech: status.assignedTech
          });
        }
      }
    };

    // Check maintenance status every hour
    checkEquipment();
    const interval = setInterval(checkEquipment, 3600000);

    return () => clearInterval(interval);
  }, [equipment, technicians, updateEquipment]);

  return null;
}