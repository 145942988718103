import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Technician } from '../types/technician';

interface TechnicianStore {
  technicians: Technician[];
  addTechnician: (technician: Technician) => void;
  updateTechnician: (id: string, updates: Partial<Technician>) => void;
  deleteTechnician: (id: string) => void;
}

export const useTechnicianStore = create<TechnicianStore>()(
  persist(
    (set) => ({
      technicians: [],
      addTechnician: (technician) =>
        set((state) => ({ technicians: [...state.technicians, technician] })),
      updateTechnician: (id, updates) =>
        set((state) => ({
          technicians: state.technicians.map((tech) =>
            tech.id === id ? { ...tech, ...updates } : tech
          ),
        })),
      deleteTechnician: (id) =>
        set((state) => ({
          technicians: state.technicians.filter((tech) => tech.id !== id),
        })),
    }),
    {
      name: 'technician-storage',
    }
  )
);