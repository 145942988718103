import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Equipment } from '../types/equipment';

interface EquipmentStore {
  equipment: Equipment[];
  addEquipment: (equipment: Equipment) => void;
  updateEquipment: (id: string, updates: Partial<Equipment>) => void;
  deleteEquipment: (id: string) => void;
  editEquipment: (id: string, equipment: Equipment) => void;
}

export const useEquipmentStore = create<EquipmentStore>()(
  persist(
    (set) => ({
      equipment: [],
      addEquipment: (equipment) =>
        set((state) => ({ equipment: [...state.equipment, equipment] })),
      updateEquipment: (id, updates) =>
        set((state) => ({
          equipment: state.equipment.map((item) =>
            item.id === id ? { ...item, ...updates } : item
          ),
        })),
      deleteEquipment: (id) =>
        set((state) => ({
          equipment: state.equipment.filter((item) => item.id !== id),
        })),
      editEquipment: (id, updatedEquipment) =>
        set((state) => ({
          equipment: state.equipment.map((item) =>
            item.id === id ? updatedEquipment : item
          ),
        })),
    }),
    {
      name: 'equipment-storage',
    }
  )
);