import { useState } from 'react';
import { ActionButton } from './buttons/ActionButton';
import { SocialLinks } from './navigation/SocialLinks';
import { EquipmentList } from './equipment/EquipmentList';
import { AddEquipmentForm } from './equipment/AddEquipmentForm';
import { AddTechnician } from './technicians/AddTechnician';
import { TechnicianList } from './technicians/TechnicianList';
import { AddProject } from './projects/AddProject';
import { ProjectList } from './projects/ProjectList';
import { Plus, List, Users, MapPin, Phone, Mail } from 'lucide-react';

type TabType = 'equipment' | 'technicians' | 'projects';

export function MainContent() {
  const [activeTab, setActiveTab] = useState<TabType>('equipment');
  const [showForm, setShowForm] = useState(false);

  return (
    <div className="max-w-4xl mx-auto px-4 space-y-8 pb-8">
      <div className="grid grid-cols-3 gap-4">
        <ActionButton 
          variant="secondary"
          onClick={() => {
            setActiveTab('equipment');
            setShowForm(!showForm);
          }}
        >
          <div className="flex items-center justify-center gap-2">
            {showForm && activeTab === 'equipment' ? <List className="w-4 h-4" /> : <Plus className="w-4 h-4" />}
            <span>{showForm && activeTab === 'equipment' ? 'View Equipment' : 'Add Equipment/Task'}</span>
          </div>
        </ActionButton>
        <ActionButton 
          variant="secondary"
          onClick={() => {
            setActiveTab('technicians');
            setShowForm(!showForm);
          }}
        >
          <div className="flex items-center justify-center gap-2">
            <Users className="w-4 h-4" />
            <span>{showForm && activeTab === 'technicians' ? 'View Technicians' : 'Add Technician'}</span>
          </div>
        </ActionButton>
        <ActionButton 
          variant="secondary"
          onClick={() => {
            setActiveTab('projects');
            setShowForm(!showForm);
          }}
        >
          <div className="flex items-center justify-center gap-2">
            <MapPin className="w-4 h-4" />
            <span>{showForm && activeTab === 'projects' ? 'View Projects' : 'Add Project'}</span>
          </div>
        </ActionButton>
      </div>
      
      {activeTab === 'equipment' ? (
        showForm ? (
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-2xl font-bold mb-4">Add New Equipment/Task</h2>
            <AddEquipmentForm />
          </div>
        ) : (
          <EquipmentList />
        )
      ) : activeTab === 'technicians' ? (
        showForm ? (
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-2xl font-bold mb-4">Add New Technician</h2>
            <AddTechnician />
          </div>
        ) : (
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-2xl font-bold mb-4">Technicians</h2>
            <TechnicianList />
          </div>
        )
      ) : (
        showForm ? (
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-2xl font-bold mb-4">Add New Project</h2>
            <AddProject />
          </div>
        ) : (
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-2xl font-bold mb-4">Projects by Location</h2>
            <ProjectList />
          </div>
        )
      )}

      <div className="grid grid-cols-2 gap-4">
        <a 
          href="tel:9546848260"
          className="inline-block w-full"
        >
          <ActionButton variant="danger">
            <div className="flex items-center justify-center gap-2">
              <Phone className="w-4 h-4" />
              <span>Call Us</span>
            </div>
          </ActionButton>
        </a>
        <a 
          href="mailto:edward.dixon@macys.com"
          className="inline-block w-full"
        >
          <ActionButton variant="danger">
            <div className="flex items-center justify-center gap-2">
              <Mail className="w-4 h-4" />
              <span>Email Us</span>
            </div>
          </ActionButton>
        </a>
      </div>

      <SocialLinks />
    </div>
  );
}