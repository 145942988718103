import { create } from 'zustand';
import { WorkOrder } from '../types/workOrder';

interface WorkOrderStore {
  workOrders: WorkOrder[];
  addWorkOrder: (workOrder: WorkOrder) => void;
  updateWorkOrder: (id: string, updates: Partial<WorkOrder>) => void;
  deleteWorkOrder: (id: string) => void;
  getWorkOrdersByTechnician: (technicianId: string) => WorkOrder[];
  getWorkOrdersByEquipment: (equipmentId: string) => WorkOrder[];
}

export const useWorkOrderStore = create<WorkOrderStore>((set, get) => ({
  workOrders: [],
  addWorkOrder: (workOrder) =>
    set((state) => ({ workOrders: [...state.workOrders, workOrder] })),
  updateWorkOrder: (id, updates) =>
    set((state) => ({
      workOrders: state.workOrders.map((order) =>
        order.id === id ? { ...order, ...updates } : order
      ),
    })),
  deleteWorkOrder: (id) =>
    set((state) => ({
      workOrders: state.workOrders.filter((order) => order.id !== id),
    })),
  getWorkOrdersByTechnician: (technicianId) =>
    get().workOrders.filter((order) => order.technicianId === technicianId),
  getWorkOrdersByEquipment: (equipmentId) =>
    get().workOrders.filter((order) => order.equipmentId === equipmentId),
}));