import { format, parseISO, addDays } from 'date-fns';
import { MAINTENANCE_INTERVALS } from './constants';

export const formatDate = (date: string | null | undefined): string => {
  if (!date) return 'Not scheduled';
  try {
    return format(parseISO(date), 'PP');
  } catch (error) {
    return 'Invalid date';
  }
};

export const calculateNextMaintenanceDate = (
  lastMaintenance: string,
  serviceType: string
): string => {
  if (serviceType === 'repair') return '';
  
  const interval = serviceType === 'preventive' 
    ? MAINTENANCE_INTERVALS.PREVENTIVE 
    : MAINTENANCE_INTERVALS.STANDARD;

  const nextDate = addDays(parseISO(lastMaintenance), interval);
  return nextDate.toISOString().split('T')[0];
};