export const LOCATIONS = [
  '21',
  '710776A',
  '710776B',
  '710777',
  '710778',
  '757',
  '759A',
  '759B',
  '760',
  '761',
  '762',
  '768',
  '769A',
  '770A',
  '770B',
  '771A',
  '771B',
  '772',
  '773',
  '806',
  '720002',
  '720003',
  '720010',
  '720020'
] as const;

export type Location = typeof LOCATIONS[number];

export const SERVICE_TYPES = {
  'preventive': 'Regular scheduled maintenance to prevent equipment failure',
  'repair': 'Repairs performed to fix identified issues',
  'inspection': 'Routine inspection of equipment',
  'emergency': 'Emergency service required'
} as const;

export type ServiceType = keyof typeof SERVICE_TYPES;

export const MAINTENANCE_INTERVALS = {
  PREVENTIVE: 90, // 90 days for preventive maintenance
  STANDARD: 180 // 180 days for standard maintenance
} as const;