import { differenceInDays, parseISO } from 'date-fns';
import { Equipment } from '../types/equipment';
import { Technician } from '../types/technician';
import { sendNotification } from './notifications';

interface MaintenanceDateValidation {
  valid: boolean;
  message?: string;
}

export const validateMaintenanceDate = (
  lastMaintenance: string,
  nextMaintenance: string,
  notes?: string
): MaintenanceDateValidation => {
  const isAirHandler = notes?.toLowerCase().includes('air handler') || 
                      notes?.toLowerCase().includes('ahu');
  
  const lastDate = parseISO(lastMaintenance);
  const nextDate = parseISO(nextMaintenance);
  const daysDifference = differenceInDays(nextDate, lastDate);

  if (isAirHandler && daysDifference > 90) {
    return {
      valid: false,
      message: 'Air Handlers require maintenance every 90 days or less'
    };
  }

  if (daysDifference <= 0) {
    return {
      valid: false,
      message: 'Next maintenance date must be after the last maintenance date'
    };
  }

  return { valid: true };
};

export const checkMaintenanceStatus = async (
  equipment: Equipment,
  technicians: Technician[]
) => {
  const nextMaintenance = parseISO(equipment.nextMaintenance);
  const daysUntilMaintenance = differenceInDays(nextMaintenance, new Date());

  if (daysUntilMaintenance <= 30) {
    // Only notify about maintenance being needed, don't auto-assign
    return {
      requiresMaintenance: true,
      daysUntil: daysUntilMaintenance
    };
  }

  return {
    requiresMaintenance: false,
    daysUntil: daysUntilMaintenance
  };
};