import { Header } from './components/layout/Header';
import { MainContent } from './components/MainContent';
import { MaintenanceScheduler } from './components/maintenance/MaintenanceScheduler';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <div 
        className="min-h-screen bg-cover bg-center"
        style={{
          backgroundImage: 'url("https://images.unsplash.com/photo-1581092918056-0c4c3acd3789?auto=format&fit=crop&q=80")'
        }}
      >
        <div className="min-h-screen bg-white/40 backdrop-blur-sm">
          <Header />
          <MainContent />
          <MaintenanceScheduler />
        </div>
      </div>
    </QueryClientProvider>
  );
}

export default App;