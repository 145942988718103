import { useProjectStore } from '../../store/projectStore';
import { useWorkOrderStore } from '../../store/workOrderStore';
import { format } from 'date-fns';
import { MapPin, Calendar, CheckCircle, AlertCircle, Clock, PauseCircle } from 'lucide-react';
import { LOCATIONS } from '../../utils/constants';

export function ProjectList() {
  const projects = useProjectStore((state) => state.projects);
  const workOrders = useWorkOrderStore((state) => state.workOrders);

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'planned':
        return <Clock className="text-blue-500" />;
      case 'in-progress':
        return <Calendar className="text-yellow-500" />;
      case 'completed':
        return <CheckCircle className="text-green-500" />;
      case 'on-hold':
        return <PauseCircle className="text-orange-500" />;
      default:
        return <AlertCircle className="text-red-500" />;
    }
  };

  const getPriorityClass = (priority: string) => {
    switch (priority) {
      case 'urgent':
        return 'bg-red-100 text-red-800';
      case 'high':
        return 'bg-orange-100 text-orange-800';
      case 'medium':
        return 'bg-yellow-100 text-yellow-800';
      case 'low':
        return 'bg-green-100 text-green-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount);
  };

  return (
    <div className="space-y-8">
      {LOCATIONS.map((location) => {
        const locationProjects = projects.filter(
          (project) => project.location === location
        );

        if (locationProjects.length === 0) return null;

        return (
          <div key={location} className="bg-white rounded-lg shadow-md p-6">
            <div className="flex items-center gap-2 mb-4">
              <MapPin className="text-gray-500" />
              <h2 className="text-2xl font-bold">Location: {location}</h2>
            </div>
            <div className="space-y-4">
              {locationProjects.map((project) => {
                const projectWorkOrders = workOrders.filter((wo) =>
                  project.workOrders.includes(wo.id)
                );

                return (
                  <div
                    key={project.id}
                    className="border rounded-lg p-4 hover:bg-gray-50 transition-colors"
                  >
                    <div className="flex items-center justify-between mb-2">
                      <div className="flex items-center gap-2">
                        {getStatusIcon(project.status)}
                        <h3 className="font-semibold text-lg">{project.name}</h3>
                      </div>
                      <span
                        className={`px-2 py-1 rounded-full text-xs font-medium ${getPriorityClass(
                          project.priority
                        )}`}
                      >
                        {project.priority.toUpperCase()}
                      </span>
                    </div>

                    <div className="grid grid-cols-2 gap-4 text-sm text-gray-600">
                      <div>
                        <p>Start Date: {project.startDate ? format(new Date(project.startDate), 'PP') : 'Not set'}</p>
                        <p>Vendor: {project.vendor}</p>
                        <p>Cost: {formatCurrency(project.cost)}</p>
                        <p>Status: {project.status}</p>
                        {project.poNumber && <p>PO Number: {project.poNumber}</p>}
                      </div>
                      <div>
                        <p>Work Orders: {projectWorkOrders.length}</p>
                        <p>
                          Completed:{' '}
                          {
                            projectWorkOrders.filter((wo) => wo.status === 'completed')
                              .length
                          }
                        </p>
                      </div>
                    </div>

                    <div className="mt-2 text-sm">
                      <p className="font-medium">Description:</p>
                      <p className="text-gray-600">{project.description}</p>
                      {project.notes && (
                        <>
                          <p className="font-medium mt-2">Notes:</p>
                          <p className="text-gray-600">{project.notes}</p>
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}